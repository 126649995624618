<style lang="scss" scoped>
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}
</style>

<template>
	<div class="HA_public">
		<header-nav :hasLogout="!Boolean(userDesc)">{{ navText }}</header-nav>

		<protect-limit ref="protect" :productId="productId" :planId="planId" :priceId="priceId" @change="protectLimitChange">
			<span slot="title">保险起止期</span>
		</protect-limit>
		<split-line></split-line>

		<insure-form ref="insure"></insure-form>
		<split-line></split-line>

		<!-- 标的信息 -->
		<subject-form ref="subject" area businessAddress :areaValue="areaValue"></subject-form>
		<split-line></split-line>

		<!-- 被保人信息 -->
		<assured-form ref="assured" :sameInsure="true"></assured-form>
		<split-line></split-line>

		<!-- 条款 -->
		<clause v-model="check" :productId="productId"></clause>

		<page-footer></page-footer>
		<price-buy-btn :price="price" @buy="buy"></price-buy-btn>

		<loading :show="loading">提交中</loading>
	</div>
</template>

<script>
import { Toast } from 'vant';
import splitLine from '../components/splitLine.vue';
import protectLimit from '../components/protectLimit.vue';
import insureForm from '../components/insureForm.vue';
import subjectForm from '../components/subjectForm.vue';
import assuredForm from '../components/assuredForm.vue';
import clause from '../components/clause.vue';
import { http_submitBill } from '@/request/sundry';
import headerNav from '../components/headerNav.vue';

export default {
	name: 'HA_public', // 华安公众责任险
	components: {
		splitLine,
		protectLimit,
		insureForm,
		subjectForm,
		assuredForm,
		clause,
		headerNav,
		[Toast.name]: Toast,
	},
	data() {
		return {
			pid: '',
			userDesc: '',
			navText: '公众责任险-计划一',
			productId: '',
			planId: '',

			scrollY: 0,

			check: false,

			companyId: '', // 保险公司id

			priceId: '',
			singleAssuredPrice: 0, // 单个人的保费价格
			protectInfo: {}, // 保障期限信息
			assuredLength: 1, // 被保人数量

			areaValue: 0,
			loading: false,
		};
	},
	computed: {
		price: function () {
			return this.$base.floatPrice(this.singleAssuredPrice * this.assuredLength);
		},
	},
	created() {
		this.init();
	},
	beforeRouteLeave(to, from, next) {
		if (to.path.includes('/sundry/product')) {
			khs.stop();
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		back() {
			this.$router.go(-1);
		},

		init() {
			let query = this.$route.query;
			this.pid = query.pid;
			this.userDesc = query.user;
			this.navText = `${query.pName}-${query.planName}`;
			this.productId = query.productId;
			this.planId = query.plainId;
			this.companyId = query.companyId;
			this.areaValue = query.planPriceValue;
			this.singleAssuredPrice = Number(query.price);
			this.priceId = query.priceId;
		},

		// 去登录页
		toLoginPage(err) {
			if (err.message === '用户未登录') {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 保障期限改变
		protectLimitChange(info) {
			this.protectInfo = info;
		},

		async buy() {
			if (!this.check) {
				Toast('请先勾选条款');
				return false;
			}

			// 获取投保信息
			let protectData = await this.$refs.protect.getData();

			// 获取投保人信息
			let insureData = await this.$refs.insure.getData();

			// 获取标的信息
			let subjectData = await this.$refs.subject.getData();
			console.log('标的信息', subjectData);

			let allExtentionDict = this.allExtentionDict();
			let fields = ['area', 'businessAddress'];
			let subjectDataList = fields.map(key => {
				return {
					colName: allExtentionDict[key],
					colValue: subjectData[key],
				};
			});

			// 获取被保险人
			let assured = await this.$refs.assured.getData();
			let assuredList = [
				{
					birthday: assured.birthday,
					certificateContent: assured.cardNo,
					certificateType: assured.cardTypeCode,
					name: assured.name,
					sex: assured.sex,
					address: assured.address,
				},
			];

			let send = {
				insBill: {
					proId: this.productId,
					enableDate: protectData.start,
					disEnableDate: protectData.end,
					assuredSendMsg: 1,
				},
				insurer: {
					fixedFlag: Number(insureData.isFixedInsure),
					insAddress: insureData.address,
					insBirthday: insureData.birthday,
					insCredentials: insureData.cardNum,
					insCredentialsType: insureData.cardType,
					insEmail: insureData.email,
					insName: insureData.userName,
					insPhone: insureData.phone,
					insSex: insureData.sex,
					insType: insureData.insType,
					saveContactsFlag: Number(insureData.isFixedInsure),
				},
				assuredList: assuredList,
				productPriceVo: {
					comboId: this.planId,
					priceId: this.priceId,
					proId: this.productId,
					eleView1: '',
					eleView2: '',
					insureTime: this.protectInfo.insure_time,
					insureTimeUnit: this.protectInfo.insure_time_unit,
					sex: '',
					viewAgeBelong: '',
					viewTime: this.protectInfo.view_time,
				},
				extentionList: subjectDataList,
			};
			this.submit(send);
		},

		// 获取扩展字段列表
		allExtentionDict() {
			let result = {};
			let sundryFormInput = this.$store.state.sundryFormInput;
			if (typeof sundryFormInput === 'object' && Object.hasOwnProperty.call(sundryFormInput, 'otherInfo') && sundryFormInput.otherInfo.length) {
				result = sundryFormInput.otherInfo;
				sundryFormInput.otherInfo.forEach(item => {
					result[item.fieldName] = item.showName;
				});
			}
			return result;
		},

		// 提交订单
		submit(send) {
			this.loading = true;
			http_submitBill(send)
				.then(res => {
					khs.finish(res.data.id);
					this.$store.commit('set_billId', res.data.id);
					this.$router.push({
						name: 'orderPay',
						params: {
							isRedict: false,
						},
					});
				})
				.catch(err => {
					this.toLoginPage(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
